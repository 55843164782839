
import { Dispatch } from 'redux';
import { DropActions } from '../types'
import { ethers } from 'ethers'
import { RootState } from 'data/store'
import LinkdropFactory from 'abi/linkdrop-factory.json'
import { signReceiverAddress } from '@linkdrop/contracts/scripts/utils.js'
import * as dropActions from '../actions'
import * as userActions from '../../user/actions'
import { UserActions } from '../../user/types'
import { resolveENS, defineJSONRpcUrl, handleClaimResponseError } from 'helpers'
import { AxiosError } from 'axios'
import { TAccount } from 'types'
import LedgerLiveApi from "@ledgerhq/live-app-sdk"
import contracts from 'configs/contracts'
import BigNumber from "bignumber.js"

const { REACT_APP_INFURA_ID = '' } = process.env

export default function claimERC721(
  manualAddress?: string
) {
  return async (
    dispatch: Dispatch<DropActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(dropActions.setLoading(true))
    let {
      user: {
        sdk,
        address,
        llApiInstance,
        account
      },
      drop: {
        campaignId,
        isManual,
        wallet,
        tokenAddress,
        tokenId,
        weiAmount,
        expirationTime,
        linkKey,
        linkdropMasterAddress,
        linkdropSignerSignature,
        chainId
      }
    } = getState()
    if (!chainId) {
      dispatch(dropActions.setLoading(false))
      return alert(`chainId is not provided`)
    }
    if (!linkKey) {
      dispatch(dropActions.setLoading(false))
      return alert(`linkKey is not provided`)
    }

    if (!tokenAddress) {
      dispatch(dropActions.setLoading(false))
      return alert(`tokenAddress is not provided`)
    }

    if (!tokenId) {
      dispatch(dropActions.setLoading(false))
      return alert(`tokenId is not provided`)
    }

    if (!expirationTime) {
      dispatch(dropActions.setLoading(false))
      return alert(`expirationTime is not provided`)
    }

    if (!linkdropMasterAddress) {
      dispatch(dropActions.setLoading(false))
      return alert(`linkdropMasterAddress is not provided`)
    }

    if (!campaignId) {
      dispatch(dropActions.setLoading(false))
      return alert(`campaignId is not provided`)
    }

    if (!linkdropSignerSignature) {
      dispatch(dropActions.setLoading(false))
      return alert(`linkdropSignerSignature is not provided`)
    }

    if (!wallet) {
      dispatch(dropActions.setLoading(false))
      return alert(`wallet is not provided`)
    }

    if (!address && manualAddress) {
      const jsonRpcUrl = defineJSONRpcUrl({ chainId: 1, infuraPk: REACT_APP_INFURA_ID })
      const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)
      const addressResolved = await resolveENS(manualAddress, provider)
      if (addressResolved) {
        dispatch(userActions.setAddress(addressResolved))
        address = addressResolved
      } else if (addressResolved === null) {
        dispatch(dropActions.setLoading(false))
        return dispatch(dropActions.setStep('error_no_connection'))
      } else {
        dispatch(dropActions.setLoading(false))
        return alert('Provided address or ens is not correct')
      }
    }
    
    let finalTxHash

    try {
      if (isManual) {
        finalTxHash = await claimManually(
          account,
          llApiInstance,
          chainId,
          linkKey,
          address,
          weiAmount || '0',
          tokenAddress,
          tokenId,
          expirationTime,
          linkdropMasterAddress,
          campaignId,
          linkdropSignerSignature,
          dispatch
        )    
      } else {
        const { success, errors, txHash, message } = await sdk.claimERC721({
          weiAmount,
          nftAddress: tokenAddress,
          tokenId,
          expirationTime,
          linkKey,
          linkdropSignerSignature,
          receiverAddress: address,
          campaignId
        })
  
        if (success) {
          finalTxHash = txHash
        } else {
          console.log({ errors })
        }
      }

      if (finalTxHash) {
        dispatch(dropActions.setHash(finalTxHash))
        dispatch(dropActions.setStep('claiming_process'))
      }
      
    } catch (error: any | AxiosError) {
      handleClaimResponseError(dispatch, error)
    }
    dispatch(dropActions.setLoading(false))
  } 
}

const claimManually = async (
  account: TAccount | null,
  llApiInstance: LedgerLiveApi | null,
  chainId: number,
  linkKey: string,
  address: string,
  weiAmount: string,
  nftAddress: string,
  tokenId: string,
  expirationTime: string,
  linkdropMasterAddress: string,
  campaignId: string,
  linkdropSignerSignature: string,
  dispatch: Dispatch<DropActions> & Dispatch<UserActions>
) => {
  try {
    if (!llApiInstance) {
      throw new Error('Ledger Live Api instance not found')
    }

    if (!account) {
      throw new Error('Account was not selected')
    }
    const contract = contracts[chainId]

    if (!contract) {
      throw new Error('chain id is not suportedd')
    }
    const linkId = new ethers.Wallet(linkKey).address
    const receiverSignature = await signReceiverAddress(linkKey, address)

    const iface = new ethers.utils.Interface(LinkdropFactory.abi)

    const data = await iface.encodeFunctionData("claimERC721", [
      weiAmount,
      nftAddress,
      tokenId,
      expirationTime,
      linkId,
      linkdropMasterAddress,
      campaignId,
      linkdropSignerSignature,
      address,
      receiverSignature
    ])

    const transaction = await llApiInstance.signTransaction(account.id, {
      //@ts-ignore
      family: "ethereum",
      gasLimit: new BigNumber(150000) as (BigNumber & { _isBigNumber: boolean }), // Ensure you have enough gas
      amount: new BigNumber(0) as (BigNumber & { _isBigNumber: boolean }),
      recipient: contract.factory,
      data: Buffer.from(data.replace("0x", ""), "hex")
    })
    

    const txHash = await llApiInstance.broadcastSignedTransaction(account.id, transaction)
    return txHash

  } catch (err) {
    dispatch(dropActions.setStep('error'))
    console.log({ err })
  }
}